<template>
  <v-expansion-panels accordion="" multiple v-model="panels" focusable :readonly="$vuetify.breakpoint.mdAndUp">
    <v-expansion-panel class="mb-3" v-for="(item,index) in items" :key="index" @change="posicion(item.ref)" :id="item.ref" >
      <v-expansion-panel-header>
        <div class="subtitle-1 font-weight-medium primary--text">{{item.titulo}}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mt-5">
        <component  :is="item.componente" :defensor="defensor"></component>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  props:{
    defensor:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  components: {
    'InfoRegistro':()=>import("@/components/RegistroDefensor"),
    'InfoEstado':()=>import("@/components/EstadoRegistroDefensor"),
  },
  data() {
    return {
      panels: [],
      cantidad: 2,
      maxItems:this.$vuetify.breakpoint.mdAndUp?2:2,
      items:[
        {
          titulo:'1.- Estado del Registro',
          componente:'InfoEstado',
        },
        // {
        //    titulo:'2.- Información del Registro',
        //   componente:'InfoRegistro',
        // }
      ]
    };
  },
  mounted(){
    this.iniciarPanel()
  },
  methods:{
    iniciarPanel(){
       var i=0;
       var array=[];
       for (let index = 0; index < this.maxItems; index++) {
         array.push(i);
         i++;
       } 
       this.panels=array;
    },
     posicion(data){
      if(data!=null){
        this.$vuetify.goTo(`#${data}`)
      }
    }
  }
};
</script>